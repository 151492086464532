.main {
  width: 100%; 
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  position: relative; 
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px; 
}

.dropdownContainer {
  display: flex;
  align-items: center;
  padding-right: 10px;
  z-index: 10;
}

.languageSelect {
  padding: 13px;
  border: 1px solid #ccc;
  border-radius: 4px; 
}

.text-color--gray {
  color: rgb(59, 59, 59);
}

.text-align--center {
  text-align: center;
}
